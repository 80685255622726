// @flow
import React, { memo, type Node } from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import NavLink from './NavLink';
import NavWithoutLink from './NavWithoutLink';
import MobileNavLink from './MobileNavLink';

const menuOuterContent = {
  alignItems: 'center',
  borderRadius: 4,
  color: palette.white,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: 8,
  marginLeft: 16,
  marginRight: 16,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
};

const useStyles = makeStyles({
  menuOuter: {
    ...menuOuterContent,
    '&:hover': {
      backgroundColor: palette.brand10,
    },

    '&.active': {
      backgroundColor: palette.brand35,
    },

    '&.bottom': {
      marginTop: 'auto',
    },
  },

  menuOuterNoLink: {
    ...menuOuterContent,
    '&.bottom': {
      marginTop: 'auto',
    },
  },

  menuInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menuIconBox: {
    maxWidth: 40,
    maxHeight: 40,
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },

  menuText: {
    fontSize: 15, // Prevent cutting off 'Hacker Toolkits (Attack)'
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '100%',
    // display: 'flex', // Ellipsis doesn't work if set
    maxWidth: 165,
    alignItems: 'center',
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
  },
});

type Props = {
  to?: string | null,
  exact?: boolean,
  text: string,
  children: Node,
  redirect?: boolean,
  drawerOpen?: boolean,
  target?: '_self' | '_blank' | '_parent' | '_top',
  isUsingMobileRouting?: boolean,
};

function NavItem({
  to,
  exact = false,
  redirect = false,
  drawerOpen = false,
  target = '_blank',
  text,
  children,
  isUsingMobileRouting = false,
}) {
  const styles = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (!drawerOpen) {
      setOpen(true);
    }
  };

  return to ? (
    isUsingMobileRouting ? (
      <MobileNavLink
        to={to}
        exact={exact}
        redirect={redirect}
        target={target}
        className={styles.menuOuter}
        activeStyle={{ backgroundColor: palette.brand35 }}
      >
        {/* Controlled tooltip only renders when drawer is closed */}
        <Tooltip
          disableInteractive
          title={text}
          placement={'top'}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <div className={styles.menuInner}>
            <div className={styles.menuIconBox}>{children}</div>
            <div className={styles.menuText}>{text}</div>
          </div>
        </Tooltip>
      </MobileNavLink>
    ) : (
      <NavLink
        to={to}
        text={text}
        exact={exact}
        redirect={redirect}
        target={target}
        className={styles.menuOuter}
        activeStyle={{ backgroundColor: palette.brand35 }}
      >
        {/* Controlled tooltip only renders when drawer is closed */}
        <Tooltip
          disableInteractive
          title={text}
          placement={'top'}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <div className={styles.menuInner}>
            <div className={styles.menuIconBox}>{children}</div>
            <div className={styles.menuText}>{text}</div>
          </div>
        </Tooltip>
      </NavLink>
    )
  ) : (
    <NavWithoutLink
      className={styles.menuOuterNoLink}
      activeStyle={{ backgroundColor: palette.brand35, cursor: 'none' }}
    >
      {/* Controlled tooltip only renders when drawer is closed */}
      <Tooltip disableInteractive title={text} placement={'top'} open={open} onClose={handleClose} onOpen={handleOpen}>
        <div className={styles.menuInner}>
          <div className={styles.menuIconBox}>{children}</div>
          <div className={styles.menuText}>{text}</div>
        </div>
      </Tooltip>
    </NavWithoutLink>
  );
}

export default memo<Props>(NavItem);

// @flow
import '@dt/global';
import React, { memo } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider, InMemoryCache } from '@apollo/client';
import { TrackingProvider } from '@dt/analytics';
import { materialTheme } from '@dt/theme';
import { ThemeProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import ApplicationRouting from './ApplicationRouting';
import { render } from 'react-dom';
import { ErrorBoundary } from '@dt/components';
import { initialize } from '@dt/session';
import { getStore } from './redux';
import { getMiddleware, runSagas } from './redux/sagas';
import { createApolloClient } from './apollo';
import { LocationProvider, createHistory, createMemorySource } from '@reach/router';
import type { ResolverMap } from 'graphql-tools';
import type { Node } from 'react';
import 'reactflow/dist/style.css';

type Props = {
  graphQLMocks?: void | ResolverMap,
  initialRoute?: string,
};

/*
 * Root providers for the application.
 *
 * Think of this kindof like an IoC container providing base functionality for the rest of the application.
 * This component is used to bootstrap the application and to bootstrap the testing environment.
 *
 * Sets up the following:
 * - Redux
 * - Apollo client (graphql)
 * - Material UI
 * - Analytics & tracking
 *
 * @param graphQLMocks - Mocks apollo client resolvers.
 * @param initialRoute - Starting navigational context - Used for testing.
 * @param children - Sub compoennts to render.
 */
export const ApplicationProviders = function ApplicationProviders({
  graphQLMocks,
  initialRoute,
  children,
}: {
  ...Props,
  children: Node,
}) {
  // Setup redux store.
  const reduxSaga = getMiddleware();
  const store = getStore([reduxSaga]);
  global.__DT_STORE = store;
  runSagas(reduxSaga);

  // Setup apollo cache.
  const cache = new InMemoryCache({
    typePolicies: {
      PaginationInformation: {
        keyFields: false,
      },
      V2PolicyRulesListResponsePolicyRule: {
        // NOTE: Used to uniquely identify policy rules.
        //       Stats have to be included because this type isn't a true entity type.
        //       Meaning it changes based on the query params to the endpoint.
        keyFields: ['id', 'included_policy_violation_stats'],
      },
    },
  });
  global.__DT_APOLLO_CACHE = cache;

  // Setup apollo client.
  const client = createApolloClient(cache, graphQLMocks);

  // Setup material UI.
  const muiTheme = createTheme(adaptV4Theme(materialTheme()));

  // Setup navigational router.
  const history = createHistory(
    // $FlowFixMe - Window is an available option here.
    !initialRoute ? window : createMemorySource(initialRoute),
  );

  return (
    <LocationProvider history={history}>
      <ReduxProvider store={store}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={muiTheme}>
            <TrackingProvider>{children}</TrackingProvider>
          </ThemeProvider>
        </ApolloProvider>
      </ReduxProvider>
    </LocationProvider>
  );
};

/*
 * Application entry point.
 *
 * Sets up the following:
 * - Root providers
 * - Routing for pages
 *
 * @param initialRoute - Starting navigational context - Used for testing.
 * @param graphQLMocks - Mocks apollo client resolvers.
 */
export const Application = memo<Props>(function Application({ initialRoute, graphQLMocks }: Props) {
  return (
    <ApplicationProviders initialRoute={initialRoute} graphQLMocks={graphQLMocks}>
      <ApplicationRouting />
    </ApplicationProviders>
  );
});

/*
 * Runs the application.
 */
export const main = function main() {
  // Single page application DOM container.
  const domContainer = document.getElementById('react');
  if (!domContainer) {
    // Should never happen.
    alert(`Couldn't start the application. Clear cache and refresh.`);
    console.error(`There is no DOM element with id=react!`);
    return;
  }

  // Request authorization.
  initialize();

  // Render the application to the DOM container.
  render(
    <ErrorBoundary>
      <Application />
    </ErrorBoundary>,
    domContainer,
  );
};
